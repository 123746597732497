import { BackgroundColor, Color } from '../types'

const colorPairs: Record<string, { bg: BackgroundColor; color: Color }> = {
  claimed: { bg: `characterPrimaryBackground`, color: `characterPrimaryText` },
  available: { bg: `royalblue`, color: `white` },
  // droppingSoon: { bg: `red`, color: `white` },
  notYetAvailable: { bg: `silver`, color: `black` },

  // a: { bg: `#81e0e5`, color: `#fd3293` },
  // b: { bg: `#340b0c`, color: `#9b92fc` },
  // c: { bg: `#fa3e37`, color: `#072e5a` },
  // d: { bg: `#43fd74`, color: `#000` },
  // e: { bg: `characterPrimaryBackground`, color: `characterPrimaryText` },
  // f: { bg: `royalblue`, color: `white` },
  // g: { bg: `red`, color: `white` },
  // h: { bg: `silver`, color: `black` },
}

const sum = (code) => code.split(``).reduce((a, b) => +a + +b, 0)

export function getBackgroundColor({ code, owner, status }): BackgroundColor {
  // const purple = `hsl(244deg 96% 82%)`
  // const blue = `hsl(208deg 96% 80%)`
  // const green = `hsl(141deg 48% 72%)`
  // const pink = `hsl(328deg 90% 85%)`
  // const yellow = `hsl(41deg 85% 71%)`
  // const gray = `hsl(207deg 13% 83%)`

  // if (sum(code) % 6 === 0) return purple
  // if (sum(code) % 5 === 0) return blue
  // if (sum(code) % 4 === 0) return green
  // if (sum(code) % 3 === 0) return pink
  // if (sum(code) % 2 === 0) return yellow
  // if (sum(code) % 1 === 0) return gray

  // return `hsl(${code} 42.5% 82.5%)`

  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 8 === 0) return colorPairs.a.bg
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 7 === 0) return colorPairs.b.bg
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 6 === 0) return colorPairs.c.bg
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 5 === 0) return colorPairs.d.bg
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 4 === 0) return colorPairs.e.bg
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 3 === 0) return colorPairs.f.bg
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 2 === 0) return colorPairs.g.bg
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 1 === 0) return colorPairs.h.bg

  if (status === `unavailable` && owner.id) return colorPairs.claimed.bg
  if (status === `available`) return colorPairs.available.bg
  // if (status === `dropping-soon`) return colorPairs.droppingSoon.bg

  return colorPairs.notYetAvailable.bg
}

export function getColor({ code, owner, status }): Color {
  // return `black`

  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 8 === 0) return colorPairs.a.color
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 7 === 0) return colorPairs.b.color
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 6 === 0) return colorPairs.c.color
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 5 === 0) return colorPairs.d.color
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 4 === 0) return colorPairs.e.color
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 3 === 0) return colorPairs.f.color
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 2 === 0) return colorPairs.g.color
  // if (code.split(``).reduce((a, b) => +a + +b, 0) % 1 === 0) return colorPairs.h.color

  if (owner.id) return colorPairs.claimed.color
  if (status === `available`) return colorPairs.available.color
  // if (status === `dropping-soon`) return colorPairs.droppingSoon.color

  return colorPairs.notYetAvailable.color
}
