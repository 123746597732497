import { features } from '../constants'
import { featuresAll } from '../constants/features'
import { Face } from '../types'
import { getBackgroundColor, getColor } from './colors'
import { formatCollectionFace, formatFace, formatFaceWithBorder, getOptions } from './faces'
import { getStatus, getStatusStyles } from './statuses'

function getFaces(): Face[] {
  let count = 0
  let items = []
  let number = 1

  featuresAll.head.forEach((headFeature, headIndex) => {
    featuresAll.eyes.forEach((eyesFeature, eyesIndex) => {
      featuresAll.nose.forEach((noseFeature, noseIndex) => {
        featuresAll.mouth.forEach((mouthFeature, mouthIndex) => {
          featuresAll.chin.forEach((chinFeature, chinIndex) => {
            if (
              count % headFeature.rarity === 0 &&
              count % eyesFeature.rarity === 0 &&
              count % noseFeature.rarity === 0 &&
              count % mouthFeature.rarity === 0 &&
              count % chinFeature.rarity === 0
            ) {
              items = [
                ...items,
                {
                  code: `${headIndex}${eyesIndex}${noseIndex}${mouthIndex}${chinIndex}`,
                  number: number++,

                  // TODO: Remove placeholder logic
                  owner: {
                    id: [2, 3, 4, 5, 6, 7, 8, 9].includes(number) ? `TEST_OWNER_ID` : undefined,
                    name: [2, 3, 4, 5, 6, 7, 8, 9].includes(number) ? `TEST_OWNER_NAME` : undefined,
                  },
                  status: [10, 11, 12, 13, 14, 15, 16, 17].includes(number) ? `available` : `unavailable`,
                },
              ]
            }

            count++
          })
        })
      })
    })
  })

  return items
}

const faces = getFaces()

export {
  faces,
  formatCollectionFace,
  formatFace,
  formatFaceWithBorder,
  getBackgroundColor,
  getColor,
  getOptions,
  getStatus,
  getStatusStyles,
}

interface Attribute {
  trait_type: `Head` | `Eyes` | `Nose` | `Mouth` | `Chin`
  value: string
}

export function getAttributes(code): Attribute[] {
  const [head, eyes, nose, mouth, chin] = code

  function formatValue(attribute) {
    return `${attribute.slice(0, 1).toUpperCase()}${attribute.slice(1)}`
  }

  return [
    { trait_type: `Head`, value: formatValue(features.headOptions[head]) },
    { trait_type: `Eyes`, value: formatValue(features.eyesOptions[eyes]) },
    { trait_type: `Nose`, value: formatValue(features.noseOptions[nose]) },
    { trait_type: `Mouth`, value: formatValue(features.mouthOptions[mouth]) },
    { trait_type: `Chin`, value: formatValue(features.chinOptions[chin]) },
  ]
}

export function getAmountOfCharactersWithAttribute({ attribute }) {
  return faces.filter(({ code }) => [...getAttributes(code).map(({ value }) => value)].includes(attribute)).length
}

export function getAmountOfCharacters() {
  return faces.length
}
