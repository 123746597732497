import { features } from '../constants'

export function getOptions(): { attribute: string; name: string }[] {
  const attribute = [
    ...features.head,
    ...features.eyes,
    ...features.nose,
    ...features.mouth,
    ...features.chin,
    //
  ]

  const collections = [
    ...Array(features.head.length).fill('head'),
    ...Array(features.eyes.length).fill('eyes'),
    ...Array(features.nose.length).fill('nose'),
    ...Array(features.mouth.length).fill('mouth'),
    ...Array(features.chin.length).fill('chin'),
  ]

  const names = [
    ...features.headOptions,
    ...features.eyesOptions,
    ...features.noseOptions,
    ...features.mouthOptions,
    ...features.chinOptions,
  ]

  return attribute.map((attribute, index) => {
    return {
      attribute,
      collection: collections[index],
      name: names[index],
    }
  })
}

export function formatCollectionFace(name: string) {
  return `${
    features.headOptions.includes(name)
      ? `${getOptions().filter((option) => option.name === name)[0].attribute}`
      : `       `
  }
${
  features.eyesOptions.includes(name)
    ? `${getOptions().filter((option) => option.name === name)[0].attribute}`
    : `  . .  `
}
${
  features.noseOptions.includes(name)
    ? `${getOptions().filter((option) => option.name === name)[0].attribute}`
    : `   L   `
}
${
  features.mouthOptions.includes(name)
    ? `${getOptions().filter((option) => option.name === name)[0].attribute}`
    : `   —   `
}
${
  features.chinOptions.includes(name)
    ? `${getOptions().filter((option) => option.name === name)[0].attribute}`
    : `       `
}`
}

export function formatFace(face: string): string {
  return `${features.head[face[0]]}
${features.eyes[face[1]]}
${features.nose[face[2]]}
${features.mouth[face[3]]}
${features.chin[face[4]]}`
}

export function formatFaceWithBorder(face: string): string {
  return `+ + + + + + +
+  ${features.head[face[0]]}  +
+  ${features.eyes[face[1]]}  +
+  ${features.nose[face[2]]}  +
+  ${features.mouth[face[3]]}  +
+  ${features.chin[face[4]]}  +
+ + + + + + +`
}
