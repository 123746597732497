import { BackgroundColor, Color, Status } from '../types'
import { faces } from './'

export function getStatus(number: number): Status {
  const face = faces.find((face) => face.number === number)

  if (face?.status === `unavailable` && face?.owner?.id) return 'Claimed'
  if (face?.status === `available`) return 'Unclaimed'
  // if (…) return 'Dropping soon'

  return 'Not yet available'
}

export function getStatusStyles(status: Status): { bg: BackgroundColor; color: Color } {
  if (status === `Unclaimed`) return { bg: `royalblue`, color: `white` }
  if (status === `Claimed`) return { bg: `characterPrimaryBackground`, color: `characterPrimaryText` }
  // if (status === `Dropping soon`) return { bg: `red`, color: `white` }

  return { bg: `silver`, color: `black` }
}
