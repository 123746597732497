const features = {
  head: [
    { string: `       `, name: `standard head`, rarity: 1 },
    { string: ` ^   ^ `, name: `cat ears`, rarity: 200 },
    { string: ` ~~~~~ `, name: `wavy hair`, rarity: 88 },
    { string: `  … …  `, name: `eyebrows`, rarity: 57 },
    { string: ` .:::. `, name: `beanie`, rarity: 22 },
    { string: `   ~   `, name: `single curl`, rarity: 90 },
    { string: ` ///\\\\ `, name: `parted bangs`, rarity: 20 },
    { string: `  _ ~  `, name: `curious eyebrows`, rarity: 130 },
    { string: ` ||||| `, name: `bangs`, rarity: 80 },
  ],

  eyes: [
    { string: `  . .  `, name: `standard eyes`, rarity: 1 },
    { string: ` —o—o— `, name: `glasses`, rarity: 21 },
    { string: `  _ _  `, name: `shut eyes`, rarity: 63 },
    { string: `  o O  `, name: `spooky eyes`, rarity: 26 },
    { string: `  × ×  `, name: `dead eyes`, rarity: 100 },
    { string: `  @ @  `, name: `side eyes`, rarity: 85 },
    { string: `  $ $  `, name: `money struck`, rarity: 4 },
    { string: `  0 0  `, name: `frightened eyes`, rarity: 8 },
    { string: `  o o  `, name: `wide eyes`, rarity: 32 },
    { string: `  _ .  `, name: `winking eyes`, rarity: 2 },
    // { string: ` [] [] `, name: `robot eyes`, rarity: 40 },
  ],

  nose: [
    { string: `   L   `, name: `standard nose`, rarity: 1 },
    { string: `   >   `, name: `pointy nose`, rarity: 5 },
    { string: `   "   `, name: `button nose`, rarity: 25 },
    { string: `   J   `, name: `curved nose`, rarity: 99 },
    { string: ` = Y = `, name: `whiskers`, rarity: 6 },
    { string: `   J   `, name: `curved nose`, rarity: 33 },
    { string: `   /   `, name: `angled nose`, rarity: 44 },
  ],

  mouth: [
    { string: `   —   `, name: `standard mouth`, rarity: 1 },
    { string: ` +---+ `, name: `robot mouth`, rarity: 300 },
    { string: `  )-(  `, name: `puffy cheeks`, rarity: 30 },
    { string: `  $#%  `, name: `potty mouth`, rarity: 31 },
    { string: `   =.  `, name: `unique mole`, rarity: 12 },
    { string: `  ___  `, name: `stoic mouth`, rarity: 8 },
    { string: ` * = * `, name: `blush and lips`, rarity: 5 },
    { string: ` ,___, `, name: `small fangs`, rarity: 6 },
    { string: `  ^-^  `, name: `lower fangs`, rarity: 12 },
    { string: `   o   `, name: `whistling`, rarity: 72 },
    { string: `   O   `, name: `yelling`, rarity: 40 },
  ],

  chin: [
    { string: `       `, name: `standard chin`, rarity: 1 },
    { string: `   -   `, name: `flat chin`, rarity: 3 },
    { string: `   .   `, name: `dimpled chin`, rarity: 10 },
    { string: `   w   `, name: `goatee`, rarity: 55 },
    { string: `   ~   `, name: `sprout`, rarity: 40 }, // jib
  ],
}

export const featuresAll = features

export const head = features.head.map((feature) => feature.string)
export const eyes = features.eyes.map((feature) => feature.string)
export const nose = features.nose.map((feature) => feature.string)
export const mouth = features.mouth.map((feature) => feature.string)
export const chin = features.chin.map((feature) => feature.string)
export const headOptions = features.head.map((feature) => feature.name)
export const eyesOptions = features.eyes.map((feature) => feature.name)
export const noseOptions = features.nose.map((feature) => feature.name)
export const mouthOptions = features.mouth.map((feature) => feature.name)
export const chinOptions = features.chin.map((feature) => feature.name)

// category: head
// collection: cat ears
// attribute: cat ears
// name:
// number: 1
// code: 22222

// 1000 = 2 * 5 * 4 * 5 * 5
